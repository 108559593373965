<script setup lang="ts">
import type { UnwrapRef } from 'vue'

const { prev, next } = useContent()
function isHidden(page: UnwrapRef<typeof prev | typeof next>) {
  return page?._path?.startsWith('/legal') || page?._path?.startsWith('/security')
}
</script>

<template>
  <div class="mt-10 border-t pt-8 lg:flex lg:flex-row">
    <LayoutPrevNextButton
      :prev-next="isHidden(prev) ? null : prev"
      side="left"
    />
    <span class="flex-1" />
    <LayoutPrevNextButton
      :prev-next="isHidden(next) ? null : next"
      side="right"
    />
  </div>
</template>
